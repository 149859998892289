import axios from 'axios';
import { getAuthToken } from '../util';

const baseUrl = process.env.REACT_APP_AUTH_BASE_URL as string;

const getHeaders = () => {
  let headers = {};
  const authToken = getAuthToken();
  if(authToken) {
    headers = {
      ...headers,
      'Authorization': `Bearer ${authToken}`
    }
  }

  return headers;
}

export const getAuthApi = async (url: string): Promise<unknown> => {
  const headers = getHeaders();
  return axios.get(`${baseUrl}${url}`, { headers });
};

export const postAuthApi = async (url: string, body?: any): Promise<unknown> => {
  const headers = getHeaders();
  return axios.post(`${baseUrl}${url}`, body, { headers });
};
