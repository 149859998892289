import React, { Fragment, useEffect, useState } from 'react';
import { Button, Container, Form, InputGroup } from "react-bootstrap";
import { postApi } from "../../config/api";
import styled from 'styled-components';

interface SmsInputProps {
  phoneNumber?: string;
}

export function SmsInput(props: SmsInputProps): JSX.Element {
  const [smsNumber, setSmsNumber] = useState(props.phoneNumber ?? '')
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);  // used to prevent spam clicks on send sms button

  const sendSms = async (): Promise<void> => {
    try {
      setIsButtonDisabled(true);
      await postApi(`/users/${smsNumber}/send-invite?nft=true`);
      setTimeout(() => { setIsButtonDisabled(false); }, 5000);
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert('Oops! Something went wrong. Please try again!');
      setIsButtonDisabled(false);
    }
  };
  return(
    <Container>
      <InputGroup>
        <SmsFormInput
          value={smsNumber}
          placeholder="Phone number"
          onChange={(event: any) => { setSmsNumber(event.target.value); }}
        />
        <FormButton onClick={() => { sendSms(); }} disabled={isButtonDisabled}>
          <LinkCopy>
            Send Link
          </LinkCopy>
        </FormButton>
      </InputGroup>
    </Container>
  );
}

export const SmsFormInput = styled(Form.Control)`
  box-sizing: border-box;
  margin: 0;
  background: transparent;
  fill: none;
  border: 1px solid #E0C3FC;
  border-radius: 12px;

  color: #FFFFFF;
  font-family: 'ClashGroteskBold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  outline: none;
  box-shadow: none;

  // style for placeholder text
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #FFFFFF;
    font-family: 'ClashGroteskBold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
  }

  &:focus {
    background: transparent;
    fill: none;
    color: #FFFFFF;
    font-family: 'ClashGroteskBold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    border: 1px solid #E0C3FC;
    box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2);
 }
`

const LinkCopy = styled.span`
  font-family: 'ClashGroteskBold';
  font-style: normal;
  font-weight: 700;
  color: #31224C;
  text-transform: uppercase;
`;

const FormButton = styled(Button)`
  /* Gradient */
  background: linear-gradient(29.34deg, #E0C3FC -0.33%, #8EC5FC 124.58%);
  border-radius: 8px;
  outline: none;
  box-shadow: none;
  border: none;
`;