import styled from "styled-components";

export const PurpleText = styled.p`
  font-family: 'ClashGroteskBold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  background: linear-gradient(29.34deg, #E0C3FC -0.33%, #8EC5FC 124.58%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

export const WhiteText = styled.p`
  font-family: 'NotoJP';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #FFFFFF;
`;

export const HeaderText = styled.p`
  margin-bottom: '25px';
  box-sizing: border-box;

  font-family: 'ClashGroteskBold';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 46px;

  letter-spacing: 0.03em;
  text-transform: uppercase;

  color: #ffffff;

  @media only screen and (min-width: 844px) {
    display: flex;
  }
`

export const ClashGroteskBold = styled.p`
  font-family: 'ClashGroteskBold';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 46px;
  /* identical to box height, or 153% */

  /* text-align: center; */
  text-transform: uppercase;

  color: #FFFFFF;
  margin: 0;
`

export const ClashGrotesk = styled.p`
  font-family: 'ClashGrotesk';
  font-style: normal;
  /* font-weight: 700;
  font-size: 30px;
  line-height: 46px;
  letter-spacing: 0.03em;
  text-transform: uppercase; */
`

export const AuthDesc = styled.p`
  font-family: 'NotoJP';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #868686;
`

export const NotoSansJP = styled.p`
  font-family: 'NotoJP';
  /* font-style: normal; */
`