import { ClashGroteskBold } from "../../shared/Texts";

interface CircleProps {
  step: number;
  currentPage: number;
  label?: string;
}

function Circle({ step, currentPage, label }: CircleProps) {
  return (
    <ClashGroteskBold>
      <div className="relative">
          <div className="flex items-center justify-center">
              <div className={`flex items-center justify-center w-[28px] h-[28px] rounded-full ${currentPage >= step ? "bg-[#cdc4fc]" : "bg-[#C0C0C0]"}`}>
              <div className="text-[16px] font-bold text-[#1f1d2b]">
                  {step < currentPage ?
                  <img src="checkmark.svg" className="h-[15px] w-auto" />
                  : step
                  }
              </div>
          </div>
        </div>
        <span className="absolute text-[#5f5c74] text-[14px] sm:text-[16px] top-full left-1/2 transform -translate-x-1/2 -mt-[4px] whitespace-nowrap font-bold uppercase">{label}</span>
      </div>
    </ClashGroteskBold>
  );
}

function ProgressBar(props: any) {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex items-center">
        <Circle step={1} currentPage={props.currentPage} label={"Wallet"}/>
        <div className="h-[2px] w-[40px] sm:w-[90px] bg-[#868686] mx-2"></div>

        <Circle step={2} currentPage={props.currentPage} label={"Sign Up"}/>
        <div className="h-[2px] w-[40px] sm:w-[90px] bg-[#868686] mx-2"></div>

        <Circle step={3} currentPage={props.currentPage} label={"Claim"}/>
        <div className="h-[2px] w-[40px] sm:w-[90px] bg-[#868686] mx-2"></div>

        <Circle step={4} currentPage={props.currentPage} label={"Equip"}/>
      </div>
    </div>
  );
};

export default ProgressBar;