import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import LogoTitle from '../../image/LogoTitle.png';
import GalaxyBg from '../../assets/GalaxyBg.mov';
import { GrayText, Timer } from './Timer';
import { AstroSetByStartDate, activeAvatar, activeGear, isGearStart } from '../utils';
import Results from '../../image/AboutStakes/Results.png';
import Gears from '../../image/AboutStakes/Gears.png'
import AvatarGif from '../../image/AboutStakes/Avatars.gif'
import '../../App.css';

import DiscordIcon from '../../image/SocialIcons/discord.png'
import InstagramIcon from '../../image/SocialIcons/instagram.png'
import TwitterIcon from '../../image/SocialIcons/twitter.png'
import TikTokIcon from '../../image/SocialIcons/tiktok.png'
import OpenSeaIcon from '../../image/SocialIcons/opensea.png'

import AstroShoe from '../../image/Astro/AstroShoe.png'

import Partners from '../../image/Partners/Partners.png';
import { PurpleButton, TransparentButton } from '../../shared/Buttons';
import { ClashGrotesk, ClashGroteskBold, HeaderText } from '../../shared/Texts';
import { StakesNavbar } from '../Nav/StakesNavbar';

import { GalaxyBackground } from '../../shared/Backgrounds';
import { Helmet } from 'react-helmet';

export function ClaimHome(): JSX.Element {
  const [expired, setExpired] = useState(false);

  //todo: padding on xs device are off
  return (
    <>
      <GalaxyBackground>
        <Helmet>
          <meta property="og:url" content="https://app.playwithstakes.com/moon" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Stakes - Build Your Sports God" />
          <meta property="og:description" content="Mint these limited edition astronaut boots for free!" />
          <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/stakes-3fde0.appspot.com/o/FzFgwOrWYAApzdt.jpeg?alt=media" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="app.playwithstakes.com/moon" />
          <meta property="twitter:url" content="https://app.playwithstakes.com/moon" />
          <meta name="twitter:title" content="Stakes - Build Your Sports God" />
          <meta name="twitter:description" content="Mint these limited edition astronaut boots for free!" />
          <meta name="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/stakes-3fde0.appspot.com/o/FzFgwOrWYAApzdt.jpeg?alt=media" />
        </Helmet>
        <StakesNavbar />
        {/* Title */}
        {/* show this title on smaller screens */}
        <div className="px-[50px] sm:px-[0px]">
          <Container style={{ width: '100%', height: '50%', padding: '50px 70px 0px 70px'}} className="d-lg-none">
            <Image src={LogoTitle} alt={LogoTitle} style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
            <ClashGroteskBold>
              Predict. Win. Collect. Repeat.
            </ClashGroteskBold>
          </Container>
        </div>

        {/* Active Gear and Avatar */}
        <Container>
          <Row style={{ margin: '100px', marginBottom: '50px' }}>
            <Col xs={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} lg={6}>
              {/* Title */}
              {/* show this title on larger screen */}
              <Container style={{ width: '100%', height: 105 }} className="d-none d-lg-block">
                <Image src={LogoTitle} alt={LogoTitle} style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                <ClashGroteskBold>
                  Predict. Win. Collect. Repeat.
                </ClashGroteskBold>
              </Container>

              {/* Hide this when screen gets too small */}
              <div className="d-none d-md-block justify-content-center">
                {activeGear.end != '' ?
                  // <Image src={activeGear.src} alt={activeGear.src} style={{ width: 186, height: 186, margin: '100px auto 30px auto' }} /> :
                  <Image src={AstroShoe} alt={AstroShoe} style={{ width: 186, height: 186, margin: '100px auto 30px auto' }} /> :
                  <Image src={activeGear.src} alt={activeGear.src} style={{ width: 285.47, height: 229.11, margin: '100px auto 30px auto' }} />
                }
              </div>

              <PurpleButton className="text-nowrap" style={{ margin: '10px' }} disabled={expired} href="/claim">
                Claim this collectible item!
              </PurpleButton>

              {/* only show timer, if gear is still available */}
              {/* <Timer deadline={activeGear.end} expired={expired} setExpired={setExpired} /> */}
            </Col>

            <Col xs={{ span: 12, order: 1 }} md={{ span: 12, order: 2 }} lg={6}>
              <PurpleText>
                Legendary Astronaut Set
              </PurpleText>

              <div style={{ position: 'relative'}}>
                <Image src={activeAvatar} alt={activeAvatar} style={{ height: '437px', width: '248px', margin: '0 auto' }} />

                {/* absolutely position gear for phone view or when screen gets small*/}
                <div className="d-md-none d-lg-none">
                  <Image src={activeGear.src} alt={activeGear.src} style={{ width: 92, height: 92, position: 'absolute', left: 0, bottom: 0 }} />
                </div>

              </div>
              <div style={{ padding: '10px' }}>
                <GrayText>
                  Collect all 7 items to unlock the Stakes Galaxy Helmet
                </GrayText>
              </div>
            </Col>
          </Row>

          {/* Unused for now*/}
          {/* <Row style={{ display: 'flex', alignItems: 'end' }}>
            <PurpleText>
              Already Playing Stakes?
            </PurpleText>
            <WhiteText>
              Claim this limited item directly within the App!
            </WhiteText>
          </Row> */}
        </Container>

        <IosHr className="d-lg-none"/>

        {/* About Stakes */}
        <Container className="lg mt-0">
          <Container>
            <Row>
              <Col>
                <HeaderText>About Stakes</HeaderText>
                <p style={{ color: "#FFFFFF", fontFamily: 'NotoJP', fontSize: '21px', padding: '20px 90px 0px 90px' }}>
                  Sports Gods are a unique NFT collection earned for free by making sports
                  predictions in Stakes. When you play Stakes you are given an avatar with
                  7 different equipable elements that you can fully customize. Join over 45,000
                  players and get a head start on building your Sports God with this free item!
                </p>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col lg={4} style={{ padding: '20px 10px 20px' }}>
                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', height: '250px' }}>
                  <AboutImage src={Results} alt={Results} />
                </div>
                <div>
                  <ClashGroteskBold><span style={{ color: '#CDC4FC' }}>Predict</span> The Game</ClashGroteskBold>
                  <AboutDesc>Predict sports and compete with other sports fans for <span style={{ fontWeight: 700 }}>free</span> with to earn rep, rank up, and win rewards.</AboutDesc>
                </div>
              </Col>
              <Col lg={4} style={{ padding: '20px 10px 20px' }}>
                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', height: '250px'}}>
                    <AboutImage src={AvatarGif} alt={'Loading...'} />
                </div>
                <div>
                  <ClashGroteskBold><span style={{ color: '#CDC4FC' }}>Win</span>  Rewards</ClashGroteskBold>
                  <AboutDesc>Earn new gear piece-by-piece and build a <span style={{ fontWeight: 700 }}>Sports God</span> that represents YOUR identity as a fan!</AboutDesc>
                </div>
              </Col>
              <Col lg={4} style={{ padding: '20px 10px 20px' }}>
                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', height: '250px'}}>
                  <AboutImage src={Gears} alt={Gears} />
                </div>
                <div>
                  <ClashGroteskBold><span style={{ color: '#CDC4FC' }}>Collect</span> Gear</ClashGroteskBold>
                  <AboutDesc>Every piece of gear in an <span style={{ fontWeight: 700 }}>individual NFT</span> so you can buy, sell & trade any item on OpenSea to equip your avatar.</AboutDesc>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>

        <IosHr className="d-lg-none"/>

        {/* Items Available */}
        <div className="px-[50px] sm:px-[0px]">
          <Container className="lg mt-5">
            <HeaderText>Collect the Full Set</HeaderText>
            {/* <p style={{ color: "#FFFFFF", fontFamily: 'NotoJP', fontSize: '21px', padding: '20px 90px 0px 90px' }}>
              The exclusive To The Moon Astronaut set will only be
              available during the launch of our new wallet integration feature.
              Claim the Astronaut boots for free and start collecting the full set by connecting your wallet!
              Earn the remaining gear items by playing Stakes each week or purchasing directly from our Opensea marketplace.
              <span style={{ fontWeight: 700 }}> Only 250 complete sets will be released</span>, so don’t miss it!
            </p> */}
            <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              {/* NOTE: Use Grid to show all gear items */}
            </Row>

            <div className="px-[50px] sm:px-[0px]">
              <Container style={{ marginTop: '50px', maxWidth:"1026px" }}>
                <div style={{ display: 'flex', flexWrap: "wrap", justifyContent: 'center' }}>
                  {Object.entries(AstroSetByStartDate).map(([start , item]) => (
                    <div style={{ margin: '5px'}} key={item.src}>
                      <GearImage src={item.src} alt={item.src} $start={start} />
                    </div>
                  ))}
                </div>
              </Container>
            </div>

            <br />

            {/* View on Opensea */}
            <TransparentButton width="320px" href="https://opensea.io/collection/sports-gods-gear" target="_blank" rel="noopener noreferrer">
              View Items on OpenSea
            </TransparentButton>

          </Container>
        </div>

        <IosHr className="d-lg-none"/>

        {/* Our partners */}
        <Container fluid style={{ background: "#101112" }} className="lg mt-3">
          <Container>
            <HeaderText style={{ padding: '30px 0px 0px 0px' }}>Our Partners</HeaderText>
            <p style={{
              fontFamily: 'NotoJPBold', fontStyle: 'normal', fontWeight: 400,
              fontSize: '21px', lineHeight: '24px', color: '#C0C0C0',
              marginBottom: '35px', padding: '30px 100px 0px 100px'
            }}>
              Industry leaders in media, crypto, and sports are backing Stakes
            </p>
            <Image style={{ margin: '0 auto', padding: '0px 70px 130px 70px' }} src={Partners} alt={Partners} />
          </Container>
        </Container>

        {/* Footer */}
        <footer style={{ padding: 150 }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-around" }}>
            <a href="https://twitter.com/playwithstakes" target="_blank" rel="noopener noreferrer">
              <Image src={TwitterIcon} alt={TwitterIcon} />
            </a>
            <a href="https://discord.com/invite/EuPTBHBB7N" target="_blank" rel="noopener noreferrer">
              <Image src={DiscordIcon} alt={DiscordIcon} style={{marginLeft: '10px'}}/>
            </a>
            <a href="https://opensea.io/collection/sports-gods-gear" target="_blank" rel="noopener noreferrer">
              <Image src={OpenSeaIcon} alt={OpenSeaIcon} style={{marginLeft: '10px'}}/>
            </a>
            <a href="https://www.tiktok.com/@playwithstakes" target="_blank" rel="noopener noreferrer">
              <Image src={TikTokIcon} alt={TikTokIcon} style={{marginLeft: '10px'}}/>
            </a>
            <a href="https://www.instagram.com/playwithstakes/" target="_blank" rel="noopener noreferrer">
              <Image src={InstagramIcon} alt={InstagramIcon} style={{marginLeft: '10px'}}/>
            </a>
          </div>
        </footer>
      </GalaxyBackground>
    </>
  );
}

export const WhiteNotoJP = styled.span`
  box-sizing: border-box;

  font-family: 'NotoJPBold';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 46px;

  letter-spacing: 0.03em;
  text-transform: uppercase;

  color: #FFFFFF;
`;

const PurpleText = styled.p`
  font-family: 'ClashGroteskBold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;

  /* Gradient */
  background: linear-gradient(29.34deg, #E0C3FC -0.33%, #8EC5FC 124.58%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
`;


export const BlackText = styled.span`
  font-family: 'ClashGroteskBold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;

  /* Violet Gray Dark */
  color: #31224C;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
`;

const AboutImage = styled(Image)`
    display: 'block';
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
`

const AboutDesc = styled.p`
  font-family: 'NotoJP';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #F2F2F2;
  margin: 0 auto;
  width: 308px;
`

const IosHr = styled.hr`
  border: 1px solid #5F5C74;
  margin: 0 auto;
  width: 291px;
  height: 0px;
  margin: 50px 0px 50px 0px;
`

const GearImage = styled(Image)<{ $start?: string; }>`
  opacity: ${props => isGearStart(props.$start as string) ? 1 : 0.3};
  width: 100%;
  height: 100%;
`