import React from 'react';
import styled from 'styled-components';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { FavoriteTeams } from '../FavoriteTeams/FavoriteTeams';
import AppLogo from '../../image/App.png';
import { CardContainer, CardFooterContainer, RankImages } from '../utils';

const StatsText = styled.p`
  font-family: 'ClashGroteskBold';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;

  /* Product Colors/Text Primary */
  margin: 0;
  color: #FFFFFF;
`;

const StatsLabelText = styled.p`
  /* H6. Noto Sans JP  |  Bold  |  14px */
  font-family: 'NotoJPBold';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  margin: 0;
  padding: 0;
  /* Violet Gray light */

  color: #5F5C74;
`;

const GreenBar = styled.td`
  background: #27AE60;
  border-radius: 2px 0px 0px 2px;
  height: 3px;
  width: ${(props) => (props.width)};;
  
`;

const RedBar = styled.td`
  background: #C51D31;
  border-radius: 2px;
  height: 3px;
`;

export const RankImage = styled(Image)`
  width: 151px;
  height: 151px;
  margin: 0 auto;
`;

export function LeagueSeasonCard({ stats }: any): JSX.Element {
  const {
    league_name: leagueName, league_sport: leagueSport, rank,
    win_ratio: winRatio, wager_loss_count: wagerLossCount = 0,
    wager_win_count: wagerWinCount = 0,
  } = stats || {};
  return (
    <div>
      <Row className='pt-[17px]'>
        <Col>
          <StatsText>
            {`${wagerWinCount}-${wagerLossCount}`}
          </StatsText>
          <StatsLabelText>Record</StatsLabelText>
        </Col>
        <Col>
          <StatsText>{winRatio || '-'}</StatsText>
          <StatsLabelText>Win Ratio</StatsLabelText>
        </Col>
      </Row>
      <Row className="pb-[10px]" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '5px' }}>
        <table style={{ width: 280 }}>
          <tbody>
            <tr>
              <GreenBar width={`${winRatio * 100}%`} />
              <RedBar />
            </tr>
          </tbody>
        </table>
      </Row>
    </div>
  );
}
