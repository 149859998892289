import styled from "styled-components";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { Form } from "react-bootstrap";

export const FormInput = styled(PhoneInput)`
    width: 295px;
    height: 50px;
    margin: 0 auto;
    border: none;
    border:solid 1px #ccc;
    border-radius: 10px;
    background: transparent;
    border: 1px solid #626467;
    border-radius: 16px; 
    box-sizing: border-box;
    padding: 10px;

    > input {
        all: unset;
        background: transparent;
        font-family: 'NotoJP';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #C0C0C0;   
    }
`

export const StakesInput = styled(Form.Control)`
  all: unset;
  border: 1px solid #CDC4FC;
  border-radius: 16px;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 16px; 
  padding: 10px;
  width: 280px;
  height: 50px;

  color: #FFFFFF;
  font-family: 'NotoJP';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;


  // placeholder
  ::placeholder,
  ::-webkit-input-placeholder {
    font-family: 'NotoJP';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #C0C0C0;
  }

  // input value
  &:focus {
    background: transparent;
    fill: none;
    color: #FFFFFF;
    font-family: 'NotoJP';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    outline: none;
    box-shadow: none;
    border: 1px solid #E0C3FC;
    box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2);
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }

`
