/* eslint-disable no-nested-ternary */
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

export const GrayText = styled.span`
    font-family: 'NotoJPBold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    color: #868686;
`;
export function Timer({ deadline = moment().toString() }: any): JSX.Element {
  const parsedDeadline = useMemo(() => moment(deadline).tz("America/Los_Angeles").valueOf(), [deadline]);
  const [time, setTime] = useState(parsedDeadline - moment().tz("America/Los_Angeles").valueOf());

  useEffect(() => {
    const interval = setInterval(
      () => { setTime(parsedDeadline - moment().tz("America/Los_Angeles").valueOf()); },
      1000,
    );

    return () => { clearInterval(interval); };
  }, [parsedDeadline]);

  return (
    <div>
      {deadline != '' && 
        <div className="timer">
          <GrayText>
            {'Offer Ends: '}
            {Object.entries({
              Days: time / DAY,
              Hours: (time / HOUR) % 24,
              Minutes: (time / MINUTE) % 60,
              Seconds: (time / SECOND) % 60,
            }).map(([label, value]) => (
              <span key={label}>
                <span>{`${Math.floor(value)}`.padStart(2, '0')}</span>
                <span className="text">{label === 'Days' ? 'd ' : label === 'Seconds' ? '' : ':'}</span>
              </span>
            ))}
          </GrayText>
        </div>
      }
    </div>
  );
}
