// import React from 'react';
// import styled from 'styled-components';
// import { Col, Container, Image, Row } from 'react-bootstrap';
// import { FavoriteTeams } from '../FavoriteTeams/FavoriteTeams';
// import AppLogo from '../../image/App.png';
import { useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import 'react-phone-number-input/style.css'
import { postAuthApi } from '../../config/authApi';
import { setAuthToken } from '../../util';
import { getApi, patchApi, postApi } from '../../config/api';
import { ClashGroteskBold, HeaderText } from '../../shared/Texts';

import './Auth.css'
import { PurpleButton } from '../../shared/Buttons';
import { FormInput, StakesInput } from '../../shared/Forms';
import { ErrorBox } from '../../shared/Error';
import { track } from '../../config/analytics';

interface LoginProps {
  authenticationSuccess: () => void;
  walletAddress: string;
  onAlreadyClaimed: () => void;
  onLogin?: (phoneNumber: string) => void;
}

// const loginPageIndex = {
//   0: 'Enter Phone Number',
//   1: 'Enter Code',
//   2: 'Enter Username / Email'
// } as any

export function Login(props: LoginProps): JSX.Element {
  const { authenticationSuccess, walletAddress, onAlreadyClaimed, onLogin } = props;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [apiFormattedPhoneNumber, setapiFormattedPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [haveError, setHaveError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [username, setUsername] = useState("");
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);

  const [index, setIndex] = useState(0);

  async function login(event: any) {
    event.preventDefault();
    setLoading(true);
    let response: any;
    try{
    response = await postAuthApi(`/auth/sms/start?number=${phoneNumber}`);
    setapiFormattedPhoneNumber(response?.data?.norm_sms)
    } catch(e) {
      console.log(`got error: ${JSON.stringify(e)}`)
      setHaveError(true)
    }

    handleCarouselChange(index+ 1)
    if (onLogin) {
      onLogin(phoneNumber);
    }
    setLoading(false);
  }

  function updatePhoneNumber(value:any) {
    setPhoneNumber(value);
  }

  function updateVerificationCode(e: any) {
    setVerificationCode(e.target.value);
  }

  function updateEmail(e: any) {
    setEmailAddress(e.target.value);
    setMessage(''); // reset error message on email change
  }

  function updateUsername(e: any) {
    setUsername(e.target.value);
    setMessage(''); // reset error message on username change
  }

  const handleCarouselChange = (selectedIndex: any) => {
    // track('Login Flow', {
    //   loginPage: loginPageIndex[index] 
    // })
    setIndex(selectedIndex);
  };

  const handleReenterPhoneNumber = () => {
    setPhoneNumber("");
    handleCarouselChange(0);
  }

  const submitCode = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    let response: any;
    try {
      response = await postAuthApi(`/auth/sms/finish`, {
        norm_sms: apiFormattedPhoneNumber,
        auth_code: verificationCode,
      });
      const tokenResponse = response?.data;

      await setAuthToken(tokenResponse.token);
      let userResponse: any;

      try {
        userResponse = await getApi('/users/claim-info');
      } catch (e) {
        console.log('user doesnt exist');
      }

      const claimInfo = userResponse?.data;

      if (!claimInfo?.has_claimed_boots) {
        if (claimInfo?.has_setup_account) {
          if (claimInfo?.has_connected_wallet) {
            //user has an account and has already connected their wallet
            authenticationSuccess();
          } else {
            // user has an account but has not connected their wallet
            // save their wallet address from previous step and continue
            await postApi(`/users/complete-web-setup`, {
              walletAddress,
            });

            authenticationSuccess();
          }
        } else {
          // user has not signed up for stakes yet
          handleCarouselChange(2);
        }
      } else {
        onAlreadyClaimed()
      }

      track('Phone number verified', {
        phoneNumber,
        verificationCode
      });
    } catch (e) {
      console.log(`got error: ${JSON.stringify(e)}`);
      setHaveError(true);
    }

    setLoading(false);
  };

  const createAccount = async () => {
    setIsCreatingAccount(true);
    // check if username exists
    try {
      // call auth api to join
      const createUserResponse: any = await postAuthApi('/auth/sms/join',
      {
        screen_name: username,
        email: emailAddress
      });
      console.log(createUserResponse);

      await setAuthToken(createUserResponse?.data?.token)

      await new Promise(resolve => setTimeout(resolve, 1500)); // wait for user before making next request

      const updatedUser = await postApi(`/users/complete-web-setup`,
      {
        walletAddress,
      });

      track('Create Account Successful', {
        username,
        email: emailAddress,
        userToken: createUserResponse?.data?.token,
        phoneNumber,
        walletAddress
      });

      authenticationSuccess()
    } catch(e: any){
      if(e.response.status === 409) {
        setMessage('this username or email is already in use!')
      }else if(e.response.status === 422) {
        setMessage('username should contain only letters/numbers and length should be between 2 and 25')
      }else {
        setMessage('Looks like something went wrong. Please reach out to info@playwithstakes.com for more details!')
      }
    } finally {
      setIsCreatingAccount(false);
    }
  }

  return (
      <div className='mt-[50px] sm:mt-[100px]'>
        <div>
          <div className="flex items-center justify-center py-5 px-4 sm:px-6 lg:px-8">
            <div
              className="max-w-md w-full space-y-8"
              style={{ minWidth: 500, minHeight: 420 }}
            >
              {/* <div>
                <img
                  className="login-stakes-image"
                  src={require("../../image/App.png")}
                alt="logo"
                />
              </div> */}
              <Carousel
                activeIndex={index}
                touch={false}
                controls={false}
                indicators={false}
              >
                <Carousel.Item>
                  <ClashGroteskBold>
                    Your Phone Number
                  </ClashGroteskBold>

                  {/* note: import non bold noto */}
                  <p className='desc'>
                    We’ll text a code to verify your phone number.
                  </p>

                  <form className="mt-8" onSubmit={(e) => login(e)}>
                    <div className="mb-5">
                      {/* <label className="block text-sm font-bold text-slate-700 mb-1">
                        Phone Number *
                      </label> */}
                      <FormInput
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="US"
                        value={phoneNumber}
                        onChange={(e: any) => updatePhoneNumber(e)}
                        placeholder="Enter phone number"
                      />
                    </div>
                    <div className="tos">
                      By joining you agree to our
                      <a href="https://playwithstakes.com/terms/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none'}}>
                        <span style={{ color: '#CDC4FC'}}> terms of service</span>
                      </a> and
                      <a href="https://playwithstakes.com/privacy-policy/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none'}}>
                        <span style={{ color: '#CDC4FC'}}> privacy policy</span>.
                      </a>
                    </div>
                    <div
                      className="text-center"
                      title={
                        phoneNumber === "" ? "Phone number is required" : ""
                      }
                    >
                      <PurpleButton
                        width="287px"
                        height="48px"
                        className="btn-primary"
                        disabled={phoneNumber === "" || loading}
                        onClick={(e: any) => login(e)}
                        disabledButton={!phoneNumber}
                      >
                        Send me the code
                      </PurpleButton>
                    </div>
                  </form>
                </Carousel.Item>
                <Carousel.Item>
                  <ClashGroteskBold>
                    What's the Code?
                  </ClashGroteskBold>
                  <div className="desc">
                    Enter the code we just sent to {phoneNumber}
                  </div>
                  <Form className="mt-8 align-center" onSubmit={(phoneNumber != "" || !loading) ? (e: any) => submitCode(e) : undefined}>
                    <Form.Group
                      className="mb-3"
                      controlId="verificationCode"
                    >
                      <StakesInput
                        type="text"
                        placeholder="Verification Code"
                        value={verificationCode}
                        onChange={(e: any) => updateVerificationCode(e)}
                      />
                    </Form.Group>
                    <a
                      href="#"
                      onClick={() => {
                        handleReenterPhoneNumber();
                      }}
                      className="sms-retry"
                    >
                      Re-enter Phone Number
                    </a>
                    <PurpleButton
                      className="btn-primary"
                      disabled={phoneNumber === "" || loading}
                      onClick={(e: any) => submitCode(e)}
                      disabledButton={!verificationCode}
                      height="48px"
                      width="287px"
                    >
                      Next
                    </PurpleButton>
                  </Form>
                </Carousel.Item>
                <Carousel.Item>
                  <ClashGroteskBold>
                    Create your account
                  </ClashGroteskBold>
                  <div className="desc">Just in case we have to contact you about something important.</div>
                  <Form className="mt-8 align-center">
                    {/* todo: freeze input once create account button is pressed -- unfreeze if error creating account */}
                    <Form.Group
                      className="mb-3"
                      controlId="username"
                    >
                      <StakesInput
                        type="text"
                        placeholder="User Name"
                        value={username}
                        onChange={(e: any) => updateUsername(e)}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="email"
                    >
                      {/* todo: add input validation */}
                      <StakesInput
                        type="email"
                        placeholder="Email Address"
                        value={emailAddress}
                        onChange={(e: any) => updateEmail(e)}
                      />
                    </Form.Group>

                    {/* throw error on screen */}
                    {(message) && <ErrorBox>{message}</ErrorBox>}

                    {/* todo: only allow button to be tapped once & add creating... state */}
                    <PurpleButton
                      className="btn-primary"
                      disabled={isCreatingAccount}
                      disabledButton={isCreatingAccount}
                      onClick={(e: any) => createAccount()}
                      height="48px"
                      width="287px"
                      style={{ marginTop: '100px' }}
                    >
                      {isCreatingAccount ? 'Creating account...' : 'Create account'}
                    </PurpleButton>
                  </Form>
                </Carousel.Item>
              </Carousel>
              {haveError ? (
                <div className="mt-4 text-red-400 text-xs text-center">
                  {message}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
  );
}
