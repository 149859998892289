import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Image, NavDropdown } from 'react-bootstrap';
import StakesLogo from '../../image/WhiteLogo.png';
import BurgerMenu from '../../image/burger-menu.png';

const NavLink = styled(Nav.Link)`
  font-family: 'NotoJP';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;

  &.active {
    color: ${props => props.theme.orange }
  }
`

const CustomNavDropdown = styled(NavDropdown)`
  font-family: 'NotoJP';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  color: #FFFFFF; 

  > a {
    color: #FFFFFF;

    &:hover {
      color: #FFFFFF;
    }
  }
`


export function StakesNavbar(): JSX.Element {
    return (
    <Container style={{ padding: '0px 100px 0px 100px'}}>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="https://playwithstakes.com/">
            <Image src={StakesLogo} />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span>
            <Image src={BurgerMenu} alt={BurgerMenu} />
          </span>
          </Navbar.Toggle>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              style={{ maxHeight: '100px'}}
              className="gap-3 px-1 ms-auto"
            >
              <NavLink href="/moon">Free Item</NavLink>
              <NavLink href="https://playwithstakes.com/sportsgod/">Sports God</NavLink>
              <CustomNavDropdown title="About Us" id="basic-nav-dropdown">
                <NavDropdown.Item href="https://playwithstakes.com/stakes-gameplay/">How To Play</NavDropdown.Item>
                <NavDropdown.Item href="https://playwithstakes.com/about/">Mission</NavDropdown.Item>
              </CustomNavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Container>
  )
}

export default Navbar