import styled from "styled-components";
import GalaxyBg from '../image/GalaxyBg.jpg';

export const GalaxyBackground = styled.div`
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
  /* z-index: 100; */
  background-image: url('${GalaxyBg}');

  background-size: 'cover';
  background-repeat: 'no-repeat';
  background-position: 'center';
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`

