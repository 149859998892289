import React from 'react';
import styled from 'styled-components';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { FavoriteTeams } from '../FavoriteTeams/FavoriteTeams';
import AppLogo from '../../image/App.png';
import { CardContainer, CardFooterContainer, RankImages } from '../utils';
import { PurpleText, WhiteText } from '../../shared/Texts';
import { PurpleButton, TransparentButton } from '../../shared/Buttons';


export function Auth(): JSX.Element {
  return (
      <div>
        <div style={{ padding: 10}}>
          <PurpleButton width="287px" height="48px">Sign in</PurpleButton>
        </div>
        <div style={{ padding: 10}}>
          <TransparentButton width="287px" height="48px">Create an Account</TransparentButton>
        </div>
      </div>
  );
}
