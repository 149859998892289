import { useEffect, useState } from "react";
import { Login } from "../Login/Login";
import { ClaimSuccessful, MintIt } from "./MintIt";
import { WalletConnect } from "./WalletConnect";
import { Auth } from "../Login/Auth";
import { getAuthToken } from "../../util";
import { getApi } from "../../config/api";
import ProgressBar from "../ProgressBar/ProgressBar";
import { GalaxyBackground } from "../../shared/Backgrounds";
import { track } from "../../config/analytics";

interface ClaimProps {
  isInMetamaskMobile?: boolean;
}

export function Claim({ isInMetamaskMobile = false }: ClaimProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [walletAddress, setWalletAddress] = useState("");
  const [page, setPage] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");

  const [isAuthenticated, setIsAuthenticated] = useState(!!getAuthToken() ?? false);

  const onWalletConnected = (walletAddress: string) => {
    track('Connect Wallet Successful', {
      walletAddress
    });

    setWalletAddress(walletAddress);
    setPage(2);
  }

  const authenticationSuccess = () => {
    track('Authentication Successful', {
      phoneNumber,
      walletAddress,
      token: getAuthToken()
    });

    setPage(3);
  }

  const onAlreadyClaimed = () => {
    track('Already Claimed', {
      walletAddress
    });

    setPage(4);
  }

  const onMintSuccessful = () => {
    track('Nft Claim Successful', {
      walletAddress,
      phoneNumber,
      token: getAuthToken(),
      isAuthenticated
    });

    setPage(4);
  }

  useEffect(() => {
    const fetchClaimInfo = async () => {
      try {
        const userResponse: any = await getApi('/users/claim-info');
        const claimInfo = userResponse?.data;

        if (claimInfo?.has_claimed_boots) {
          setPage(4);
        } else if (!claimInfo?.has_connected_wallet) {
          setPage(1)
        } else if (claimInfo?.has_setup_account) {
          setPage(3);
        }

        setIsLoading(false);
      } catch (e) {
        console.log('user doesnt exist');
        setIsLoading(false);
      }
    };

    if (isAuthenticated) {
      fetchClaimInfo();
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <>
    <GalaxyBackground>
      <div className="mt-[20px] sm:mt-[50px] mb-[30px]">
        <ProgressBar currentPage={page}/>
      </div>

      {isLoading &&
        <div className="text-white">
          {/* Loading... */}
        </div>
      }

      {!isLoading && (
        <>
            {page === 0 &&
              <Auth />
            }
            {page === 1 &&
              <WalletConnect onWalletConnected={onWalletConnected} isInMetamaskMobile={isInMetamaskMobile}/>
            }
            {page === 2 &&
              <Login authenticationSuccess={authenticationSuccess} walletAddress={walletAddress} onAlreadyClaimed={onAlreadyClaimed} onLogin={(num: string) => setPhoneNumber(num)}/>
            }
            {page === 3 &&
              <MintIt onMintSuccessful={onMintSuccessful}/>
            }
            {page === 4 &&
              <ClaimSuccessful phoneNumber={phoneNumber}/>
            }
        </>
      )}
    </GalaxyBackground>
{/*
    <footer>
      <PurpleText>
        Already Playing Stakes?
      </PurpleText>
      <WhiteText>
        Claim this limited item directly within the App!
      </WhiteText>
    </footer> */}
  </>
  );
}
