import Analytics from 'analytics';
// @ts-ignore
import segmentPlugin from '@analytics/segment'

const segmentWriteKey = process.env.REACT_APP_SEGMENT_WRITE_KEY as string;

const analytics = Analytics({
    app: 'awesome-app',
    plugins: [
        segmentPlugin({
            writeKey: segmentWriteKey
        })
    ]
})

export const page = (properties: any) => {
    analytics.page(properties);
}

export const track = (name: string, properties: any) => {
    analytics.track(name, properties);
}
