import React, { useState } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import LogoTitle from '../../image/LogoTitle.png';
import ClaimBackground from '../../image/ClaimBackground.png';
import { GrayText, Timer } from './Timer';
import AppLogo from '../../image/App.png';
import { RankImage } from '../LeagueSeasonCard/LeagueSeasonCard';
import { BlackText, WhiteNotoJP } from './ClaimHome';
import { useAccount } from "wagmi";
import { PurpleButton } from '../../shared/Buttons';
import { getApi, postApi } from '../../config/api';
import { getAuthApi } from '../../config/authApi';
import { AuthDesc, ClashGroteskBold } from '../../shared/Texts';
import { SmsInput } from '../Input/SmsInput';
import AppStoreLogo from '../../image/app-store.png';
import { activeAvatar, activeGear } from '../utils';
import FadeLoader from "react-spinners/FadeLoader";
import { APP_STORE_LINK } from '../constants';

export function MintIt(props: any): JSX.Element {
  const { onMintSuccessful } = props;
  const [expired, setExpired] = useState(false);
  const [gearClaimed, setGearClaimed] = useState(false);
  const { address } = useAccount();

  const handleClaimGear = async () => {
    setGearClaimed(true);
    setTimeout(() => onMintSuccessful(), 2500);

    try {
      const claimBootsResponse = await postApi('/users/claim-boots');

      if (claimBootsResponse) {
        console.log(claimBootsResponse);
      }
    } catch (e){
      console.log(e);
    }
  };

  return (
    <>
      <div className='mt-[50px] sm:mt-[75px]'>
              <Row className="justify-content-md-center">
                <Col md={6}>
                  <Container>
                    <div>
                      <Image src={LogoTitle} alt={LogoTitle} style={{ width: 281, height: 72, objectFit: 'contain', margin: '0 auto' }} />
                    </div>
                    <div>
                      <WhiteNotoJP style={{ fontSize: 15, fontWeight: 500, lineHeight: 0 }}>
                        {'Predict.\t   Win.   Collect.    Repeat.'}
                      </WhiteNotoJP>
                    </div>
                  </Container>
                </Col>
              <Col md={2}>
                <div className="hidden sm:block">
                <Image src={activeAvatar} alt={activeAvatar} style={{ width: '100px', height: '160px', margin: '0 auto' }} />
                </div>
              </Col>
              <Col md={1}>
                <div className="hidden sm:block">
                  <p style={{ color: "#5F5C74", fontFamily: 'NotoJP'}}>Legendary Astronaut Set</p>
                </div>
              </Col>
              </Row>

          {!gearClaimed ?
            <>
              <div className='flex justify-center my-[30px]'>
                <Image src={activeGear.src} alt={activeGear.src} style={{ width: 186, height: 186, margin: '100px auto 30px auto' }} /> :
                {/* <img src={activeGear}/> */}
              </div>
              {/* <Row>
                <Image className="src={activeGear} alt={activeGear} style={{ marginTop: '70px', marginBottom: '30px', width: '100%', height: '100%' }} />
              </Row> */}
              <Row>
                <Col>
                  <PurpleButton style={{ margin: '10px' }} disabled={expired} width="287px" height="52px" onClick={handleClaimGear}>
                    Claim My Gear!
                  </PurpleButton>
                    {address && <div className="text-[#868686] mb-[50px]">{`Mint to your wallet: ${address.slice(0, 6)}...${address.slice(-4)}`}</div>}
                    {' '}
                    {/* <Timer deadline={activeGear.end} expired={expired} setExpired={setExpired} /> */}
                </Col>
              </Row>
            </>
            :
            <div className='flex flex-col items-center mt-[50px]'>
            <ClashGroteskBold>
              <span className='text-[24px]'>
              Minting...
              </span>
            </ClashGroteskBold>
            <FadeLoader color={'#ffffff'} loading={true} className="mt-[15px]" />
        </div>
      }
      </div>
    </>
  );
}

export function ClaimSuccessful(props: any): JSX.Element{
  const { phoneNumber } = props;

  return (
    <div className='flex flex-col items-center mt-[50px] sm:mt-[75px]'>
      <Row className="justify-content-md-center">
        <Col md={6}>
          <Container>
            <div>
              <Image src={LogoTitle} alt={LogoTitle} style={{ width: 281, height: 72, objectFit: 'contain', margin: '0 auto' }} />
            </div>
            <div>
              <WhiteNotoJP style={{ fontSize: 15, fontWeight: 500, lineHeight: 0 }}>
                {'Predict.\t   Win.   Collect.    Repeat.'}
              </WhiteNotoJP>
            </div>
          </Container>
        </Col>
        <Col md={2}>
          <div className='mt-[10px] sm:mt-[10px] md:mt-[0px]'>
            <Image src={activeAvatar} alt={activeAvatar} style={{ width: '100px', height: '160px', margin: '0 auto' }} />
          </div>
        </Col>
        <Col md={1}>
          <p className="hidden sm:block" style={{ color: "#5F5C74", fontFamily: 'NotoJP'}}>Legendary Astronaut Set</p>
        </Col>
      </Row>
      <Row>
        <div className="d-none d-lg-block" style={{ width: '390px', margin: '50px 0px 20px' }}>
          <ClashGroteskBold style={{ fontSize: '24px', lineHeight: '30px'}}>
            Build your Sports God!
          </ClashGroteskBold>
        </div>
        <div className="d-lg-none" style={{ margin: '50px 0px 10px' }}>
          <ClashGroteskBold style={{ fontSize: '24px', lineHeight: '30px'}}>
            Build your Sports God!
          </ClashGroteskBold>
        </div>
      </Row>
      <Row>
        <div style={{ width: '290px' }}>
          <AuthDesc>
            Download the Stakes mobile app to earn more gear today!
          </AuthDesc>
        </div>
      </Row>
      <div className='mt-[15px] sm:mt-[25px] mb-[25px]'>
        <Row style={{ marginTop: '0em'}}>
          <SmsInput phoneNumber={phoneNumber}/>
        </Row>
      </div>
      {/* <Row style={{ margin: '20px'}}>
        <AuthDesc>
          Enter your phone number to receive a download link via text
        </AuthDesc>
      </Row> */}
      <Row>
        <a href={APP_STORE_LINK} target="_blank" rel="noreferrer">
          <Image alt={AppStoreLogo} src={AppStoreLogo} />
        </a>
      </Row>
      {/* <Timer deadline="June, 09, 2023 10:27:00" expired={expired} setExpired={setExpired} /> */}
    </div>
  )
}
