import { Button } from "react-bootstrap";
import styled from "styled-components";
import { ConnectButton } from '@rainbow-me/rainbowkit';

const DefaultButton = styled(Button)`
  width: ${props => props.width};
  height: ${props => props.height};
  background: none;
	/* color: inherit; */
	/* border: none; */
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: none;
  border: none;
`

export const TransparentButton = styled(DefaultButton)`
  background: transparent;
  border-radius: 8px;
	color: #FFFFFF;
  padding: 14px 20px;
  gap: 10px;
  box-sizing: border-box;
  border: 1px solid #CDC4FC;

  font-family: 'ClashGroteskBold';
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  &:hover {
    background-color: transparent !important;
    border-color: #CDC4FC !important;
  }
`

export const PurpleButton = styled(DefaultButton)`
  gap: 10px;
  padding: 14px 20px;
  background: ${props => props.disabledButton ? "#868686 !important":  "linear-gradient(29.34deg, #E0C3FC -0.33%, #8EC5FC 124.58%)"};
  /* background: linear-gradient(29.34deg, #E0C3FC -0.33%, #8EC5FC 124.58%); */
  border-radius: 8px;

  font-family: 'ClashGroteskBold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;

  /* Violet Gray Dark */
  color: #31224C;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
`;
