import axios from 'axios';
import { getAuthToken } from '../util';

const baseUrl = process.env.REACT_APP_BASE_URL as string;

const getHeaders = () => {
  let headers = {};
  const authToken = getAuthToken();

  if(authToken) {
    headers = {
      ...headers,
      'Authorization': `Bearer ${authToken}`
    }
  }

  return headers;
}

export const getApi = async (url: string): Promise<unknown> => {
  const headers = getHeaders();
  return axios.get(`${baseUrl}${url}`, {headers});
};

export const postApi = async (url: string, body?: any): Promise<unknown> => {
  const headers = getHeaders();

  return axios.post(`${baseUrl}${url}`, body ?? {}, {headers});
};

export const patchApi = async (url: string, body?: any): Promise<unknown> => {
  const headers = getHeaders();
  return axios.patch(`${baseUrl}${url}`, body, {headers});
};