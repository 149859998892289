import React from 'react';
import styled from 'styled-components';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { RankImages } from '../utils';
import './UserCard.css'
import { ClashGroteskBold, NotoSansJP } from '../../shared/Texts';
import { LeagueSeasonCard } from '../LeagueSeasonCard/LeagueSeasonCard';

export function UserCard({ username, league }: any): JSX.Element {
  const { league_name, league_sport, rank, avatar_profile_picture: avatarProfilePicture, ...stats} = league;

  return (
    <div className='card-bg'>
      <div className="d-sm-none d-md-none d-lg-none mb-[-70px]">
        <Username style={{ fontSize: '20px' }}>{username}</Username>
      </div>
      <div className="avatar-container mb-4">
        <AvatarImage src={avatarProfilePicture}/>
      </div>

      <div className="stats-container">
        <div className='stats-border'>
          <div className='stats-body'>
            <Row className="pt-[10px] pl-[10px]" style={{ position: 'relative'}}>
              <Col className='league-info'>
                <ClashGroteskBold style={{ lineHeight: '30px' }}>{league_sport}</ClashGroteskBold>
                <ClashGroteskBold style={{ color: '#FFFFFF', fontSize: '16px', lineHeight: '24px'}}>{league_name}</ClashGroteskBold>
                <NotoSansJP className="rank-text" style={{ lineHeight: '20px', textTransform: 'uppercase' }}>{rank}</NotoSansJP>
              </Col>
              <Col>
                <Image className="rank-badge" src={RankImages(rank)} />
              </Col>
            </Row>
            <Row>
              <LeagueSeasonCard stats={stats}/>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

const AvatarImage = styled(Image)`
  object-fit: cover;
  height: 50vh;
  width: 22rem;
  min-height: 15rem;
  min-width: 22rem;
`;

const Username = styled(ClashGroteskBold)`
  color: var(--violet-gray-light, #5F5C74);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
`
