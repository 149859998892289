import styled from 'styled-components';
// import { Container } from 'react-bootstrap';

import '../App.css';
import '../fonts/ClashGrotesk-Bold.ttf';
import { Container } from 'react-bootstrap';

import Bronze1 from '../image/rankBadges/badge-bronze-1.png';
import Bronze2 from '../image/rankBadges/badge-bronze-2.png';
import Bronze3 from '../image/rankBadges/badge-bronze-3.png';
import Silver1 from '../image/rankBadges/badge-silver-1.png';
import Silver2 from '../image/rankBadges/badge-silver-2.png';
import Silver3 from '../image/rankBadges/badge-silver-3.png';
import Gold1 from '../image/rankBadges/badge-gold-1.png';
import Gold2 from '../image/rankBadges/badge-gold-2.png';
import Gold3 from '../image/rankBadges/badge-gold-3.png';
import Platinum1 from '../image/rankBadges/badge-platinum-1.png';
import Platinum2 from '../image/rankBadges/badge-platinum-2.png';
import Platinum3 from '../image/rankBadges/badge-platinum-3.png';
import Diamond1 from '../image/rankBadges/badge-diamond-1.png';
import Diamond2 from '../image/rankBadges/badge-diamond-2.png';
import Diamond3 from '../image/rankBadges/badge-diamond-3.png';
import Master1 from '../image/rankBadges/badge-master-1.png';
import Master2 from '../image/rankBadges/badge-master-2.png';
import Master3 from '../image/rankBadges/badge-master-3.png';
import God1 from '../image/rankBadges/badge-god-1.png';
import God2 from '../image/rankBadges/badge-god-2.png';
import God3 from '../image/rankBadges/badge-god-3.png';

import AstroShoe from '../image/Astro/AstroShoe.png';
import AstroAcc from '../image/Astro/AstroAcc.png';
import AstroBase from '../image/Astro/AstroBase.png';
import AstroBonusHelmet from '../image/Astro/AstroBonusHelmet.png';
import AstroHelmet from '../image/Astro/AstroHelmet.png';
import AstroPant from '../image/Astro/AstroPant.png';
import AstroUpper from '../image/Astro/AstroUpper.png';
import FullAstroGear from '../image/Astro/FullAstronaut.png';
import AstroGears from '../image/Astro/AstroGears.png';
import AstroBackground from '../image/Astro/AstroBackground.png';

import moment from 'moment-timezone';

export const AppContainer = styled.div`
background: radial-gradient(49.01% 50.00% at 50.00% 50.00%, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.20) 100%), radial-gradient(110.34% 50.14% at 48.92% 48.50%, #312D44 0%, #121A2E 100%);    background-color: #282c34;
    min-height: 100vh;
    /* max-height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
`;

// remove? duplicate
export const HeaderText = styled.h1`
    font-family: 'ClashGroteskBold';
    font-style: normal;
    font-weight: 700;
    font-size: 2.25rem;

    /* font-size: 36px; */
    line-height: 33px;
    color: #5F5C74;

    @media only screen and (max-width: 850px) {
      color: var(--violet-gray-light, #5F5C74);
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      text-transform: uppercase;
      padding: 20px;
    }
`;

export const DescText = styled.p`
    font-family: 'NotoJP';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 38px;
    color: #E0C3FC;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    justify-content: center;
    margin: 0;

    @media only screen and (max-width: 512px) {
      color: var(--pink, #E0C3FC);
      text-align: center;
      font-family: NotoJP;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
      padding: 20px;
    }
`;

export const CardFooterContainer = styled.div`
  width: 312px;
  height: 96px;
  margin: 0 auto;

  margin-bottom: 15px;

  /* Dark Gray */
  background: #1F1D2B;
  box-shadow: inset 0px 4px 0px #000000, inset 0px -2px 0px 2px #442952;
  border-radius: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0;

  position: relative;
`;

export const CardContainer = styled(Container)`
  width: 333px;
  height: 441px;

  /* Dark Gray */
  background: #1F1D2B;

  border-radius: 16px;
  flex-direction: column;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  position: relative;
`;

export const ScrollView = styled.div`
  overflow-x: scroll;
  /* overflow-y: hidden; */
  /* display: flex; */
  padding: 0;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    padding: 0;
    background: transparent;
  }
`;

export const FavoriteTeamScrollView = styled(ScrollView)`
  /* display: 'flex';
  justify-content: center; */
  width: 312px;
`;

// ideally this should be returned by the backend
export const RankImages = (rank: string): string => {
  switch (rank) {
    case 'Bronze 1':
      return Bronze1;
    case 'Bronze 2':
      return Bronze2;
    case 'Bronze 3':
      return Bronze3;
    case 'Silver 1':
      return Silver1;
    case 'Silver 2':
      return Silver2;
    case 'Silver 3':
      return Silver3;
    case 'Gold 1':
      return Gold1;
    case 'Gold 2':
      return Gold2;
    case 'Gold 3':
      return Gold3;
    case 'Platinum 1':
      return Platinum1;
    case 'Platinum 2':
      return Platinum2;
    case 'Platinum 3':
      return Platinum3;
    case 'Diamond 1':
      return Diamond1;
    case 'Diamond 2':
      return Diamond2;
    case 'Diamond 3':
      return Diamond3;
    case 'Sports Master 1':
      return Master1;
    case 'Sports Master 2':
      return Master2;
    case 'Sports Master 3':
      return Master3;
    case 'Sports God 1':
      return God1;
    case 'Sports God 2':
      return God2;
    case 'Sports God 3':
      return God3;
    default:
      return '';
  }
};

export const AstroGear = {
  head: AstroHelmet,
  feet: AstroShoe,
  upperBody: AstroUpper,
  lowerBody: AstroPant,
  accessory: AstroAcc,
  platform: AstroBase,
  bonus: AstroBackground,
  full: FullAstroGear
}

// dates in PST
export const AstroSetByStartDate: Record<string, any> = {
  'June 19 2023': {
    src: AstroShoe,
    end: 'June 27 2023 11:59:59 pm'
  },
  'June 28 2023': {
    src: AstroPant,
    end: 'July 3 2023 11:59:59 pm'
  },
  'July 4 2023': {
    src: AstroUpper,
    end: 'July 10 2023 11:59:59 pm'
  },
  'July 11 2023': {
    src: AstroBase,
    end: 'July 17 2023 11:59:59 pm'
  },
  'July 18 2023': {
    src: AstroAcc,
    end: 'July 24 2023 11:59:59 pm'
  },
  'July 25 2023': {
    src: AstroBackground,
    end: 'July 31 2023 11:59:59 pm'
  },
  'August 1 2023': {
    src: AstroHelmet,
    end: 'August 7 2023 11:59:59 pm'
  },
}

// const startOfWeek = moment().startOf('isoWeek').format('MMMM D YYYY');
// export const startOfWeek = moment().tz("America/Los_Angeles").startOf("week").format('MMMM D YYYY'); // PST
const startOfWeek = 'August 1 2023';
// const startOfWeek = '';

// export const ETtoUTC = (time: string) => {
//   return moment(time, 'MMMM Do YYYY, h:mm:ss a').utc();
// }

// export const toPST = (time: string) => {
//   return moment(time).tz("America/Los_Angeles");
// }

export const isGearStart = (gearStartDate: string) => {
  return moment(startOfWeek) >= moment(gearStartDate);
}

export const activeGear = AstroSetByStartDate[startOfWeek] || { src: AstroGears, end: '' };

export const activeAvatar = AstroGear.full;
