import Cookies from 'js-cookie';

const BEARER_TOKEN_KEY = 'bearer-token';

export async function setAuthToken(authToken: string): Promise<void> {
  Cookies.set(BEARER_TOKEN_KEY, authToken);
}

export function getAuthToken(): string | undefined {
  return Cookies.get(BEARER_TOKEN_KEY);
}
