import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AppContainer } from './components/utils';
import { User } from './components/User/User';
import { Login } from './components/Login/Login';
import { Claim } from './components/Claim/Claim';
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { mainnet, polygon, optimism, arbitrum } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import './App.css';
import { ClaimHome } from './components/Claim/ClaimHome';
import { Auth } from './components/Login/Auth';
import { getAuthToken } from './util';
import { page } from './config/analytics';

const { chains, publicClient } = configureChains(
  [mainnet, polygon],
  [
    // alchemyProvider({ apiKey: process.env.ALCHEMY_ID }),
    publicProvider()
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'Stakes',
  projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID as string,
  chains
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient
})

function App(): JSX.Element {
  const location = useLocation();

  const onRouteChanged = () => {
    if (location.pathname.startsWith('/profile')){
      page({
        title: 'Share Profile'
      });
    }
    else if (location.pathname.startsWith('/moon') || location.pathname.startsWith('/claim')) {
      page({
        title: 'Moon'
      });
    }else {
      page({});
    }
  }

  useEffect(() => {
    onRouteChanged();
  }, [location]);


  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
    <AppContainer className="App">
      <Routes>
        <Route path="/" element={<ClaimHome />} />
        <Route path="/claim" element={<Claim/>} />
        {/* <Route path="/login" element={<Login authenticationSuccess={()=>{}} walletAddress={""} onAlreadyClaimed={()=>{}} />} /> */}
        <Route path="/profile/:username" element={<User />} />
        {/* todo: default to `/claim` */}
        <Route path="/moon" element={<ClaimHome />} />
        <Route path="/connect-metamask" element={<Claim isInMetamaskMobile={true}/>} />
        {/* <Route path="/auth" element={<Auth />} /> */}
      </Routes>
    </AppContainer>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
