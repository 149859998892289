import React, { Fragment, useEffect, useState } from 'react';

import { Col, Container, Row, Image, Button, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { UserCard } from '../UserCard/UserCard';
import AppStoreLogo from '../../image/app-store.png';
import { DescText, HeaderText, ScrollView } from '../utils';

import '../../App.css';
import '../../fonts/ClashGrotesk-Bold.ttf';
import { getApi, postApi } from '../../config/api';

import { SmsInput } from '../Input/SmsInput';
import { APP_STORE_LINK } from '../constants';
import { ClashGroteskBold } from '../../shared/Texts';
import './User.css'
import { WhiteNotoJP } from '../Claim/ClaimHome';

const HeaderContainer = styled(Container)`
  margin-top: 30px;
`;

// probably rename this to something else
export function User(): JSX.Element {
  const { username } = useParams() as any;
  const [user, setUser] = useState({}) as any;

  useEffect(() => {
    async function getUser(): Promise<void> {
      try {
        const response = await getApi(`/users/${username as string}/public-profile`) as any;
        if (response.status === 200) {
          setUser(response.data);
        }
      } catch (e) {
        // eslint-disable-next-line no-alert
      }
    }

    getUser();
  }, [username]);

  // get 3 most active season
  const activeLeagueWithAvatar = user.league_season_stats?.filter((league: { avatar_profile_picture: any; is_completed: boolean; }) => league.avatar_profile_picture && !league.is_completed);
  const activeLeagues = activeLeagueWithAvatar?.sort((statsOne: any, statsTwo: any) => statsTwo.wager_total_count - statsOne.wager_total_count).slice(0, 3);
  const isUserExist = Object.keys(user).length !== 0;

  let errorMessage;

  if (!isUserExist) {
    errorMessage = 'User does not exist'
  }
  if (activeLeagues?.length <= 0) {
    errorMessage = 'User is not in an active league'
  }

  return (
    <>
      {!errorMessage ? <div>
        <HeaderContainer>
          <Container>
            <Row>
              <Col>
                <HeaderText>
                  Think you can beat my prediction record?
                </HeaderText>
              </Col>
            </Row>
            <Row className="d-none d-md-block pt-[7px]">
              <ClashGroteskBold style={{ fontSize: "1.875rem" }}>{username}</ClashGroteskBold>
            </Row>
          </Container>
        </HeaderContainer>

        <div className="mt-[20px] lg:mt-[-60px]" >
          <Row style={{ justifyContent: 'center' }}>
            {activeLeagues?.map((league: any) => (
              <Col md={4} className="-mt-10" style={{ padding: 0}} key={league.id}>
                {/* avatar */}
                <UserCard username={user.screen_name} league={league}/>
                <div className="ml-[20px] mr-[20px] d-md-none d-lg-none">
                  <DescText>Use my friend code: {username} when you sign up to receive A free key Pack!</DescText>
                </div>
              </Col>
            ))}
          </Row>
        </div>

        <div>
          <Container className='d-none d-md-block'>
            <Row>
              <Col>
                <DescText>Use my friend code: {username} when you sign up to receive A free key Pack!</DescText>
              </Col>
            </Row>
          </Container>

          <Container className="p-[20px]">
            <div className="d-flex justify-content-center">
              <div>
                <a href={APP_STORE_LINK} target="_blank" rel="noreferrer">
                  <Image alt={AppStoreLogo} src={AppStoreLogo} style={{ width: '126px', height: '42px' }} />
                </a>
              </div>

              <div className="d-none d-md-block">
                <SmsInput />
              </div>
            </div>
          </Container>
        </div>
      </div> : <WhiteNotoJP>{errorMessage}</WhiteNotoJP>}
    </>
  );
}
