import { useState, useEffect } from "react";
import { useAccount, useSignMessage, useNetwork } from "wagmi";
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { PurpleButton } from "../../shared/Buttons";
import { track } from "../../config/analytics";

export function WalletConnect(props: any): JSX.Element {
  const { onWalletConnected, isInMetamaskMobile } = props;
  const { isConnected, address } = useAccount();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    if(isConnected === true) {
      onWalletConnected(address);
    }
  }, [isConnected]);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const openMetamaskMobile = () => {
    // todo: use env var to determine root url
    const pageRoot = process.env.REACT_APP_PAGE_ROOT_URL_FOR_METAMASK;
    window.location.href = `https://metamask.app.link/dapp/${pageRoot}/connect-metamask`;
  };


  const openWalletConnect = (openConnectModal: any) => {
    track('Connect Wallet Selected (Button)', {
      isMobile: isMobile,
      isInMetamaskMobile: isInMetamaskMobile
    });

    (isInMetamaskMobile || !isMobile) ? openConnectModal() : openMetamaskMobile()
  }

  return (
    <div className="mt-[250px] sm:mt-[250px]">
      {/* Wallet connect */}
      {/* <Button className="btn-primary" onClick={(e) => connectWallet()}>
        Next
      </Button> */}
      {<ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus ||
            authenticationStatus === 'authenticated');

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              'style': {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <>
                    <PurpleButton width="272px" height="52px" onClick={() => openWalletConnect(openConnectModal)} type="button">
                      {(!isInMetamaskMobile && !isMobile)? "Connect Wallet" : "Connect Metamask"}
                    </PurpleButton>
                  </>
                );
              }

              if (chain.unsupported) {
                return (
                  <button onClick={openChainModal} type="button">
                    Wrong network
                  </button>
                );
              }

              return (
                <div style={{ display: 'flex', gap: 12 }}>
                  <button
                    onClick={openChainModal}
                    style={{ display: 'flex', alignItems: 'center' }}
                    type="button"
                  >
                    {chain.hasIcon && (
                      <div
                        style={{
                          background: chain.iconBackground,
                          width: 12,
                          height: 12,
                          borderRadius: 999,
                          overflow: 'hidden',
                          marginRight: 4,
                        }}
                      >
                        {chain.iconUrl && (
                          <img
                            alt={chain.name ?? 'Chain icon'}
                            src={chain.iconUrl}
                            style={{ width: 12, height: 12 }}
                          />
                        )}
                      </div>
                    )}
                    {chain.name}
                  </button>

                  <button onClick={openAccountModal} type="button">
                    {account.displayName}
                    {account.displayBalance
                      ? ` (${account.displayBalance})`
                      : ''}
                  </button>
                </div>
              );
            })()}
          </div>
        );
      }}
      </ConnectButton.Custom>
      }
    </div>
  );
}
